import React, { useEffect, useState } from "react";
import Contact_Image from "../../assets/images/home3-slider.jpg";
import Container from "@mui/material/Container";
// import Blogone from "../../assets/images/1-1.jpg";
// import Box from "@mui/material/Box";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { useParams } from "react-router-dom";
import http from "../../config/http";

export default function Blogdetail() {
  const params = useParams();
  const [blogdata, setblogdata] = useState([]);
    // const [btrue, setbtrue] = React.useState(false);
  // const [Blogdata, setBlogdata] = React.useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    http.get(`bloglistdata/${params.id}/blogperdata`).then((response) => {
      console.log(response.data);
      setblogdata(response.data);
    });
  }, [params.id]);
  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/";

  // const [Category, setCategory] = React.useState("");
  // const [inputsearch, setinputsearch] = React.useState("");

  // const handleChange = (event) => {
  //   setCategory(event.target.value);
  // };
 

  return (
    <div>
      <div
        className="contact_div"
        style={{
          backgroundImage: `url(${Contact_Image})`,
          height: "220px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="contact_text">
          <Container maxWidth="xl">
            <h1 className="text_white">TurnOn Blog</h1>
          </Container>
        </div>
      </div>
      {/* <Container maxWidth="xl">
        <div className="blog_top_show">
          <div className="blog_div_top_search">
            <div className="blog_search">
              <input
                className="input_blog"
                placeholder="Search"
                value={inputsearch}
                onKeyDown={searchdata}
                onKeyUp={searchdata}
                onChange={(e) => setinputsearch(e.target.value)}
              />
              <div className="blog_search_bg">Search</div>
              <div className="search_icon_blog">
                <SearchTwoToneIcon />
              </div>
            </div>
          </div>
          {/* <div className="mobile_div_search">
          <Box className="select_blog">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Category}
                label="Category"
                onChange={handleChange}
              >
                <MenuItem value="Neworking">Neworking</MenuItem>
                <MenuItem value="Social Media">Social Media</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div> */}
        {/* {btrue ? (
            inputsearch.length > 0 ? (
              <div className="blog_divided_div">
                <div className="blog_search_div">
                  {Blogdata.map((b, i) => (
                    <div
                      className="blog_false"
                      key={i}
                      onClick={() => setbtrue(false)}
                    >
                      {b.name}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div> */}
      {/* </Container>  */}

      <div className="blog_div">
        <Container maxWidth="xl">
          <div className="blog_card_view">
            {blogdata.map((b, i) => (
              <div key={i}>
                <div className="card_blog_div_detail">
                  <img
                    src={`${photourl}${b.image_path2}`}
                    alt="blog"
                    className="blog_img_style"
                  />
                  <div className="blog_heading">
                    <h5
                      className="blog_heading_para_head"
                      dangerouslySetInnerHTML={{ __html: b.content1_title }}
                    />
                    <p
                      className="blog_detail"
                      dangerouslySetInnerHTML={{ __html: b.content_1 }}
                    />
                  </div>
                  <div className="blog_heading">
                    <div className="blog_user_margin">
                      <img
                        src={`${photourl}${b.image_path1}`}
                        alt="user"
                        className="user_style"
                      />
                      <span className="blog_span">{b.name}</span>
                    </div>
                    <p className="blog_date">{b.date}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
}
