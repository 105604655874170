import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Errorpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page404_s">
      <div className="page404">
        <div className="d404_divided">
          <h1>404 - Page not found</h1>
          <Link to="/" className="link_style">
            <button className="homepage_button">Go Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Errorpage;
