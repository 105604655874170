import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Container from "@mui/material/Container";
// import Logo1 from "../../assets/images/logo1.png";
// import Logo2 from "../../assets/images/logo2.png";
// import Logo3 from "../../assets/images/logo3.png";
// import Logo4 from "../../assets/images/logo4.png";
// import Logo5 from "../../assets/images/logo5.png";
import Hig from "../../assets/images/01-information-superhighway.jpg";
import N10G from "../../assets/images/02-10g-home.webp";
import Workplace from "../../assets/images/973428-shutterstock-1322061902-400x225.webp";
import http from "../../config/http";
import { Carousel } from "antd";
import { Link } from "react-router-dom";

// const itemList = [Logo1, Logo2, Logo3, Logo4, Logo5];

function Cardshow() {
  const [logo, setlogo] = useState([]);
  const [data, setdata] = useState([]);

  useEffect(() => {
    http.get(`reccomendedproduct`).then((response) => {
      // console.log(response.data.brand);
      setdata(response.data);
    });
    http.get("brandname").then((response) => {
      // console.log(response.data.brand);
      setlogo(response.data.brand);
    });
  }, []);

  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/upload/";
  return (
    <Container maxWidth="xl">
      <div className="cardshowstyle">
        <p className="takelook_style">Take a closer look</p>
        <div className="takelook">
          <div className="boxstyle">
            <div>
              <img src={Hig} alt="cardimage" className="card_image" />
            </div>
            <div className="card_text">
              <p className="card_text_line">
                Why the information superhighway is going through its biggest
                transition
              </p>
            </div>
          </div>
          <div className="boxstyle">
            <div>
              <img src={N10G} alt="cardimage" className="card_image" />
            </div>
            <div className="card_text">
              <p className="card_text_line">The 10G home is coming soon</p>
            </div>
          </div>
          <div className="boxstyle">
            <div>
              <img src={Workplace} alt="cardimage" className="card_image" />
            </div>
            <div className="card_text">
              <p className="card_text_line">The future of the workplace</p>
            </div>
          </div>
        </div>
      </div>
      <div className="card_list">
        <div className="card_list_text">
          <p className="card_list_para">
            Continuing to innovate to bring you the world's leading portfolio of
            networking solutions. Now meets next
          </p>
          <div className="slide-container banner_slide">
            <Carousel
              slidesToScroll={1}
              slidesToShow={4}
              indicators={false}
              arrows={false}
              duration={3000}
              autoplay
            >
              {logo.map((slideImage, index) => {
                return (
                  <div key={index}>
                    <img
                      src={`${photourl}${slideImage.brandimage}`}
                      className="scrool_image_home"
                      alt={slideImage.brandname}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="slide-container banner_slide_mobile">
            <Carousel
              slidesToScroll={2}
              slidesToShow={2}
              indicators={false}
              arrows={false}
              duration={3000}
              autoplay
            >
              {logo.map((slideImage, index) => (
                <div key={index}>
                  <img
                    src={`${photourl}${slideImage.brandimage}`}
                    className="scrool_image_home"
                    alt={slideImage.brandname}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div>
          <p className="recamanded_text">Recommended for you:</p>
        </div>
        <div className="list_div">
          {data.slice(0, 3).map((p, i) => (
            <>
              <div className="recommended_list">
                <div className="recommended_slide">
                  <div>
                    <img
                      src={`${photourl}${p.image_path1}`}
                      alt="bannerImage"
                      className="card_image"
                    />
                  </div>
                  <div>
                    <p className="recamanded_card">{p.product_name}</p>
                    <p
                      className="recamanded_card_text"
                      dangerouslySetInnerHTML={{ __html: p.product_short_des }}
                    />
                    
                      <div className="read">
                      <Link
                      className="link_style_2"
                      to={{
                        pathname: `/productshow${p.link}`,
                      }}
                      state={p}
                    >
                        <p className="reademore">Read more </p>
                        <ArrowForwardIosIcon />
                        </Link>

                      </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </Container>
  );
}

export default Cardshow;
