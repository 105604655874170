import React, { useEffect } from "react";
import Contact_Image from "../../assets/images/home3-slider.jpg";
import Container from "@mui/material/Container";
// import Box from "@mui/material/Box";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
// import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import http from "../../config/http";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import usePagination from "../../components/pagination";

export default function Blog() {
  const [Blog, setBlog] = React.useState([]);
  const [Blogdata, setBlogdata] = React.useState([]);

  const [inputsearch, setinputsearch] = React.useState("");
  const [btrue, setbtrue] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/";
  useEffect(() => {
    window.scrollTo(0, 0);
    http.get("bloglistdata").then((response) => {
      // console.log(response.data.blog)
      setBlog(response.data);
    });
  }, []);
  const searchdata = () => {
    http.get(`blogsearchdata?query=${inputsearch}`).then((response) => {
      console.log(response);
      setBlogdata(response.data);
      setbtrue(true);
      if (inputsearch.length < 1) {
        setbtrue(false);
      }
    });
  };
  const PER_PAGE = 10;
  const count = Math.ceil(Blog.length / PER_PAGE);
  const _DATA = usePagination(Blog, PER_PAGE);
  const handleChange = (event, value) => {
    setPage(value);
    _DATA.jump(value);
  };
  return (
    <div>
      <div
        className="contact_div"
        style={{
          backgroundImage: `url(${Contact_Image})`,
          height: "220px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="contact_text">
          <Container maxWidth="xl">
            <h1 className="text_white">TurnOn Blog</h1>
          </Container>
        </div>
      </div>
      <Container maxWidth="xl">
        <div className="blog_top_show">
          <div className="blog_div_top_search">
            <div className="blog_search">
              <input
                className="input_blog"
                placeholder="Search"
                value={inputsearch}
                onKeyDown={searchdata}
                onKeyUp={searchdata}
                onChange={(e) => setinputsearch(e.target.value)}
              />
              <div className="blog_search_bg" onClick={() => setbtrue(false)}>
                Search
              </div>
              {/* <div className="search_icon_blog">
                <SearchTwoToneIcon />
              </div> */}
            </div>
          </div>
          {/* <div className="mobile_div_search">
          <Box className="select_blog">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Category}
                label="Category"
                onChange={handleChange}
              >
                <MenuItem value="Neworking">Neworking</MenuItem>
                <MenuItem value="Social Media">Social Media</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div> */}
          {btrue ? (
            inputsearch.length > 0 ? (
              <div className="blog_divided_div">
                <div className="blog_search_div">
                  {Blogdata.map((b, i) => (
                    <div
                      className="blog_false"
                      key={i}
                      onClick={() => {
                        setbtrue(false);
                        setinputsearch(b.name);
                      }}
                    >
                      {b.name}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </Container>
      <div className="blog_div">
        <Container maxWidth="xl">
          <div className="blog_card_view">
            <div>
              {inputsearch.length > 0
                ? Blogdata.map((b, i) => (
                    <div className="card_blog_div" key={i}>
                      <img
                        src={`${photourl}${b.image}`}
                        alt="blog"
                        className="blog_img_style"
                      />
                      <div className="blog_heading">
                        <h5
                          className="blog_heading_para_head"
                          dangerouslySetInnerHTML={{ __html: b.blog_title }}
                        />
                        <p
                          className="blog_paragraph"
                          dangerouslySetInnerHTML={{
                            __html: b.blog_description,
                          }}
                        />
                      </div>
                      <div>
                        <Link
                          to={`/blogdetail${b.link}`}
                          className="link_style"
                        >
                          <button className="read_more_blog">Read More</button>
                        </Link>
                      </div>
                      <div className="blog_heading">
                        <div className="blog_user_margin">
                          <img
                            src={`${photourl}${b.profile_pic}`}
                            alt="user"
                            className="user_style"
                          />
                          <span className="blog_span">{b.name}</span>
                        </div>
                        <p className="blog_date">{b.date}</p>
                      </div>
                    </div>
                  ))
                : _DATA.currentData().slice(0, 4).map((b, i) => (
                    <div className="card_blog_div" key={i}>
                      <img
                        src={`${photourl}${b.image}`}
                        alt="blog"
                        className="blog_img_style"
                      />
                      <div className="blog_heading">
                        <h5
                          className="blog_heading_para_head"
                          dangerouslySetInnerHTML={{ __html: b.blog_title }}
                        />
                        <p
                          className="blog_paragraph"
                          dangerouslySetInnerHTML={{
                            __html: b.blog_description,
                          }}
                        />
                      </div>
                      <div>
                        <Link
                          to={`/blogdetail${b.link}`}
                          className="link_style"
                        >
                          <button className="read_more_blog">Read More</button>
                        </Link>
                      </div>
                      <div className="blog_heading">
                        <div className="blog_user_margin">
                          <img
                            src={`${photourl}${b.profile_pic}`}
                            alt="user"
                            className="user_style"
                          />
                          <span className="blog_span">{b.name}</span>
                        </div>
                        <p className="blog_date">{b.date}</p>
                      </div>
                    </div>
                  ))}
            </div>
            <div className="select_show">
              <div>
                {_DATA.currentData().slice(4, 9).map((b, i) => (
                  <div className="card_blog_div" key={i}>
                    <img
                      src={`${photourl}${b.image}`}
                      alt="blog"
                      className="blog_img_style"
                    />
                    <div className="blog_heading">
                      <h5
                        className="blog_heading_para_head"
                        dangerouslySetInnerHTML={{ __html: b.blog_title }}
                      />
                      <p
                        className="blog_paragraph"
                        dangerouslySetInnerHTML={{ __html: b.blog_description }}
                      />
                    </div>
                    <div>
                      <Link to={`/blogdetail${b.link}`} className="link_style">
                        <button className="read_more_blog_1">Read More</button>
                      </Link>
                    </div>
                    <div className="blog_heading">
                      <div className="blog_user_margin">
                        <img
                          src={`${photourl}${b.profile_pic}`}
                          alt="user"
                          className="user_style"
                        />
                        <span className="blog_span">{b.name}</span>
                      </div>
                      <p className="blog_date">{b.date}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div></div>
          </div>
          <div className="pagination-center">
            <Stack spacing={2}>
              <Pagination
                count={count}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </Stack>
          </div>
        </Container>
      </div>
    </div>
  );
}
