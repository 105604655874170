import React, { useEffect, useState } from "react";
import Contact_Image from "../../assets/images/home3-slider.jpg";
import Container from "@mui/material/Container";
import http from "../../config/http";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import KeyboardArrowUpTwoToneIcon from "@mui/icons-material/KeyboardArrowUpTwoTone";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { ToastContainer, toast } from "react-toastify";

// toast.configure();
const notify = (message) => toast(message);

export default function FAQ() {
  const [faq, setfaq] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    http.get("faq").then((response) => {
      // console.log(response.data.faq);
      setfaq(response.data.faq);
    });
  }, []);

  const [activeIndex, setActiveIndex] = React.useState("");
  const [faqdata, setfaqdata] = useState({
    name: "",
    email: "",
    question: "",
  });
  const [errorname, setErrorname] = useState("");
  const [erroremail, setErroremail] = useState("");
  const [errorqus, setErrorqus] = useState("");
  const [messages, setMessages] = useState("");

  const handleChange = (e) => {
    setfaqdata({ ...faqdata, [e.target.name]: e.target.value });
  };
  const changebg = (i) => {
    if (i === activeIndex) {
      setActiveIndex("");
    } else {
      setActiveIndex(i);
    }
  };

  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validateEmail = (event) => {
    if (faqdata.email.length <= 0) {
    } else {
      if (regex.test(faqdata.email)) {
        setMessages("");
      } else {
        setMessages("Please enter a valid email!");
      }
    }
  };

  const faqform = () => {
    if (faqdata.name.length <= 0) {
      setErrorname("Name Required");
    } else if (faqdata.email.length <= 0) {
      setErroremail("Email Required");
    } else if (faqdata.question.length <= 0) {
      setErrorqus("Question Required");
    } else {
      let formData = new FormData();
      for (const property in faqdata) {
        formData.append(property, faqdata[property]);
        // console.log(formData);
      }
      http.post("faqforms", formData).then((response) => {
        if (response.status === 200) {
          setfaqdata({
            name: "",
            email: "",
            question: "",
          });
          notify(response.data.message);
        } else {
          notify("error");
        }
      });
    }
  };
  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} theme="light" />
      <div
        className="contact_div"
        style={{
          backgroundImage: `url(${Contact_Image})`,
          height: "220px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="contact_text">
          <Container maxWidth="xl">
            <h1 className="text_white">FAQ</h1>
          </Container>
        </div>
      </div>
      <Container maxWidth="xl">
        <div className="faq_style">
          <div className="faq_qa">
            {faq.map((q, i) => (
              <div key={i}>
                <div
                  className={`faq_qus ${
                    activeIndex === i ? "faq_change" : "faq_qus"
                  }`}
                  onClick={() => changebg(i)}
                >
                  <div
                    className="faq_q_para"
                    dangerouslySetInnerHTML={{
                      __html: q.question,
                    }}
                  />
                  {activeIndex === i ? (
                    <KeyboardArrowUpTwoToneIcon />
                  ) : (
                    <KeyboardArrowDownTwoToneIcon />
                  )}
                </div>
                <div className={`faq_ans ${activeIndex === i ? "active" : ""}`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: q.answer,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="faq_ask">
            <div>
              <p className="faq_give_q_p">don’t hesitate to ask</p>
              <p className="faq_give_h">Any Other Question?</p>
            </div>
            <div className="faq_textfield">
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Your Name
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type="text"
                  className="faq_text_size"
                  value={faqdata.name}
                  name="name"
                  onChange={handleChange}
                  // helperText={faqdata.name.length > 0 ? "" : errorname}
                  error={faqdata.name.length > 0 ? "" : errorname}
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <PersonOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {faqdata.name.length > 0 ? (
                  ""
                ) : (
                  <p className="faq_error_show">{errorname}</p>
                )}
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Your Email
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type="email"
                  className="faq_text_size"
                  name="email"
                  onChange={handleChange}
                  error={faqdata.email.length > 0 ? "" : erroremail}
                  value={faqdata.email}
                  onKeyDown={validateEmail}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <MailOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {faqdata.email.length > 0 ? (
                  ""
                ) : (
                  <p className="faq_error_show">{erroremail}</p>
                )}
                {faqdata.email.length > 0 ? (
                  <p className="faq_error_show">{messages}</p>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Your Question
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type="text"
                  className="faq_text_size"
                  name="question"
                  value={faqdata.question}
                  onChange={handleChange}
                  error={faqdata.question.length > 0 ? "" : errorqus}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <EditNoteIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {faqdata.question.length > 0 ? (
                  ""
                ) : (
                  <p className="faq_error_show">{errorqus}</p>
                )}
              </FormControl>
            </div>
            <div className="faq_btn_space">
              <button className="faq_submit_btn" onClick={faqform}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
