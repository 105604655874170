/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Logo from "../../assets/images/Turn-On-Logo.png";
import SearchIcon from "@mui/icons-material/Search";
import ChatIcon from "@mui/icons-material/Chat";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import http from "../../config/http";
import { useContext } from "react";
import { MyContext } from "../../MyContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

const Team = ["Our Leadership Team"];

function Header() {
  const { setText } = useContext(MyContext);
  const [active, setactive] = useState(false);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(false);
  const [active4, setactive4] = useState(false);
  const [close, setclose] = useState(false);
  const [closearrow, setclosearrow] = useState(false);
  const [opentab, setopentab] = useState(false);
  const [openleader, setopenleader] = useState(false);
  const [downloadepdf, setdownloadepdf] = useState(false);
  const [data, setdata] = useState([]);
  const [productdata, setproductdata] = useState([]);
  const [productdatashow, setproductdatashow] = useState([]);
  const [pro, setpro] = useState("");
  const [downloade, setdownloade] = useState([]);
  const [datasub, setdatasub] = useState([]);
  const [datacat, setdatacat] = useState([]);

  const [dataform, setDataform] = useState({
    name: "",
    email: "",
    number: "",
    company_name: "",
  });
  const [errorname, setErrorname] = useState("");
  const [file, setfile] = useState("");
  const [erroremail, setErroremail] = useState("");
  const [errorphone, setErrorphone] = useState("");
  const [mobilesearch, setmobilesearch] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [messages, setMessages] = useState("");
  const [showsearch, setshowsearch] = useState("");
  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/upload/";
  const [selected, setSelected] = React.useState(0);
  function refreshPage() {
    window.location.reload(true);
  }
  const docName = "downloade.pdf";

  const handleChange = (e) => {
    setDataform({ ...dataform, [e.target.name]: e.target.value });
  };
  const Product = () => {
    setactive(!active);
    setactive1(false);
    setactive2(false);
    setactive3(false);
    setactive4(false);
  };
  const Soluction = () => {
    setactive1(false);
    setactive(false);
    setactive2(false);
    setactive3(false);
    setactive4(false);
  };
  const Contact = () => {
    setactive1(false);
    setactive(false);
    setactive2(false);
    setactive3(false);
    setactive4(false);
  };
  const Services = () => {
    setactive2(false);
    setactive(false);
    setactive1(false);
    setactive3(false);
    setactive4(false);
  };
  const Company = () => {
    setactive3(!active3);
    setactive(false);
    setactive1(false);
    setactive2(false);
    setactive4(false);
  };
  const Leader = () => {
    setactive3(false);
    setactive(false);
    setactive1(false);
    setactive2(false);
    setactive4(!active4);
  };
  const secrchicon = () => {
    setmobilesearch(!mobilesearch);
  };

  const Closeicon = () => {
    setclose(true);
    setclosearrow(true);
  };
  const Closeiconoff = () => {
    setclose(false);
    setclosearrow(false);
    setopentab(false);
    setopenleader(false);
    setdownloadepdf(false);
  };
  const opentabshow = () => {
    setopentab(!opentab);
    setclose(false);
  };
  const opentabshowtwo = () => {
    setopentab(!opentab);
    Closeiconoff();
  };
  const open_Leader = () => {
    setopenleader(true);
    setopentab(false);
    setclose(false);
    setdownloadepdf(false);
  };
  const downloadedata = () => {
    setopenleader(false);
    setopentab(false);
    setclose(false);
    setdownloadepdf(true);
  };
  const handleClose = () => setOpen(false);
  const handleOpen = (e) => {
    setOpen(true);
    setfile(e);
  };
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const validateEmail = () => {
    if (dataform.email.length <= 0) {
    } else {
      if (regex.test(dataform.email)) {
        setMessages("");
      } else {
        setMessages("Please enter a valid email!");
      }
    }
  };

  useEffect(() => {
    http.get("leaderships").then((response) => {
      setdata(response.data.leader);
    });
    http.get("product-categories").then((response) => {
      // console.log(response.data);
      setproductdata(response.data);
      setpro(response.data[0]);
      setText(response.data);
    });
    http.get(`productdetaildownload`).then((response) => {
      // console.log(response.data);
      setdownloade(response.data);
    });
  }, [setText]);
  const searchdata = () => {
    // http
    //   .get(`globalsearchdetailproduct?query=${showsearch}`)
    //   .then((response) => {
    //     console.log(response.data.dynamic_products);
    //   });
    http.get(`product-categorysearch?query=${showsearch}`).then((response) => {
      console.log(response.data);
      setdatacat(response.data);
    });
    http
      .get(`product-subcategorysearch?query=${showsearch}`)
      .then((response) => {
        console.log(response.data);
        setdatasub(response.data);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      http
        .get(`product-categories/${pro.id}/subcategories`)
        .then((response) => {
          setproductdatashow(response.data);
        });
    }, 3000);
  }, [pro.id]);

  const Productclick = (id) => {
    http.get(`product-categories/${id}/subcategories`).then((response) => {
      // console.log(response.data);
      setproductdatashow(response.data);
    });
  };
  // const handlePrint = () => {
  //   // window.open(`${photourl}${file}`, "_blank");
  //   let data = `${photourl}${file}`;
  //   // let url = window.URL.createObjectURL(data);
  //   // window.open(url, "_blank");
  //   var binaryData = [];
  //   binaryData.push(data);
  //   window.URL.createObjectURL(
  //     new Blob(binaryData, { type: "application/pdf" })
  //   );
  //   window.open(binaryData, "_blank");
  // };

  const urlfile = `${photourl}${file}`;

  const downloadByAnchorTag = (href = "#", docName = "no_name") => {
    const anchorTag = document.createElement("a");
    anchorTag.target = "_blank";
    anchorTag.href = href;
    anchorTag.download = docName;
    anchorTag.rel = "noopener noreferrer";

    anchorTag.click();

    anchorTag.remove();
  };

  const shouldBeOpenedNewWindow = () =>
    navigator.userAgent.includes("Firefox") ||
    navigator.vendor === "Apple Computer, Inc.";

  async function handlePrint() {
    if (shouldBeOpenedNewWindow) {
      console.log("is FF or Safari");
      window.open(urlfile, "_blank");
      return;
    }

    console.log("NOT FF");
    downloadByAnchorTag(urlfile, docName);
  }

  const Sendetail = () => {
    if (dataform.name.length <= 0) {
      setErrorname("Name Required");
    } else if (dataform.email.length <= 0) {
      setErroremail("Email Required");
    } else if (dataform.number.length <= 0) {
      setErrorphone("Number Required");
    } else {
      let formData = new FormData();
      for (const property in dataform) {
        formData.append(property, dataform[property]);
        // console.log(formData);
      }
      http.post(`productdetaildownloadandform`, formData).then((response) => {
        if (response.status === 200) {
          // notify(response.data.message);
          setDataform({
            name: "",
            email: "",
            number: "",
            company_name: "",
          });
          handleClose();
          handlePrint();
        }
      });
    }
  };
  return (
    <div className="headermain">
      <Container>
        <div className="topheader" onClick={Soluction}>
          <div className="headershow">
            <div className="headerlogo" onClick={refreshPage}>
              <Link to="/">
                <img src={Logo} alt="logo" style={{ width: "120px" }} />
              </Link>
            </div>
            <div className="headersearch">
              <div className="searchbox">
                <input
                  placeholder="what can we help you find?"
                  className="searchinput"
                  onKeyUp={searchdata}
                  onKeyDown={searchdata}
                  name="query"
                  value={showsearch}
                  onChange={(e) => setshowsearch(e.target.value)}
                ></input>
              </div>
              <div className="headersearchicon" onClick={searchdata}>
                <SearchIcon />
              </div>
            </div>
            <div className="contecticon_hide">
              <div className="iconcenter_hide" onClick={secrchicon}>
                <SearchIcon />
              </div>
            </div>
            <div className="contecticon_hide">
              <Link to="/contact" className="link_style">
                <div className="iconcenter_hide" onClick={Closeiconoff}>
                  <ChatIcon />
                </div>
              </Link>
            </div>
            <div className="contecticon_hide_color">
              <div className="iconcenter_hide">
                {closearrow ? (
                  <ClearTwoToneIcon onClick={Closeiconoff} />
                ) : (
                  <MenuTwoToneIcon onClick={Closeicon} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="divider">
          <Divider />
        </div>
        {showsearch.length > 0 ? (
          <div className="search_header-div">
            <div className="search_show">
              <div className="search_name_padding">
                {datacat.map((p, i) => (
                  <div key={i} onClick={() => setshowsearch("")}>
                    <Link
                      className="link_style"
                      to={{
                        pathname: `/product${p.link}`,
                      }}
                      state={p}
                    >
                      {p.product_name}
                    </Link>
                  </div>
                ))}
                {datasub.map((s, i) => (
                  <div key={i} onClick={() => setshowsearch("")}>
                    <Link
                      to={`/productshow${s.link}`}
                      state={s}
                      className="link_style"
                    >
                      {s.product_subcategory}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Container>
      <div className="header_bottom_color">
        <Container>
          <div className="header_div_contact">
            <div className="bottomheader">
              <Link to="/" className="link_style">
                <div
                  className={`headerproduct ${active1 ? "active" : ""}`}
                  onClick={() => Soluction()}
                >
                  HOME
                </div>
              </Link>
              <Link to="/about-us" className="link_style">
                <div
                  className={`headerproduct ${active2 ? "active" : ""}`}
                  onClick={() => Services()}
                >
                  ABOUT US
                </div>
              </Link>
              <div
                className={`headerproduct ${active ? "active" : ""}`}
                onClick={() => {
                  Product();
                  // Productclick(pro.id, pro.product_subcategory);
                }}
              >
                PRODUCTS
              </div>
              <div
                className={`headerproduct ${active3 ? "active" : ""}`}
                onClick={() => Company()}
              >
                DOWNLOAD/RESOURCES
              </div>
              <div
                className={`headerproduct ${active4 ? "active" : ""}`}
                onClick={() => Leader()}
              >
                LEADERSHIP TEAM
              </div>
            </div>
            <div className="header_contact">
              <Link to="/contact" className="link_style">
                <div className="contecticon" onClick={Contact}>
                  <div className="iconcenter">
                    <ChatIcon />
                  </div>
                  <div className=" headercolor">Contact Us</div>
                </div>
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <div className="divider">
        <Divider />
      </div>

      {mobilesearch ? (
        <div className="mobile_header_show">
          <div className="mobile_search_style">
            <div className="searchbox">
              <input
                placeholder="what can we help you find?"
                className="searchinput"
                onKeyUp={searchdata}
                onKeyDown={searchdata}
                name="query"
                value={showsearch}
                onChange={(e) => setshowsearch(e.target.value)}
              ></input>
            </div>
            <div className="headersearchicon" onClick={searchdata}>
              <SearchIcon />
            </div>
          </div>
          {showsearch.length > 0 ? (
            <div className="search_name_padding">
              {datacat.map((p, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setshowsearch(showsearch);
                    setmobilesearch(false);
                  }}
                >
                  <Link
                    className="link_style"
                    to={{
                      pathname: `/product${p.link}`,
                    }}
                    state={p}
                  >
                    {p.product_name}
                  </Link>
                </div>
              ))}
              {datasub.map((s, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setshowsearch("");
                    setmobilesearch(false);
                  }}
                >
                  <Link
                    to={`/productshow${s.link}`}
                    state={s}
                    className="link_style"
                  >
                    {s.product_subcategory}
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <div className={`product_show ${active ? "active" : ""} `}>
        <div className="product_part">
          <div className="part_one">
            <div className="product_show_list">
              <p className="product_details_style">Products</p>
            </div>
            <div className="pro_div_style">
              {productdata.map((p, i) => (
                <div onClick={() => setSelected(i)} key={i}>
                  <Link
                    className="link_style"
                    to={{
                      pathname: `/product${p.link}`,
                    }}
                    state={p}
                  >
                    <p
                      className={`pro_show ${
                        selected === i ? "underline" : ""
                      }`}
                      onClick={() => {
                        Productclick(p.id);
                      }}
                    >
                      {p.product_name}
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="part_two">
            <div className="product_details_show">
              <div className="product_divided_show">
                {/* {productdata.map((h, i) =>( 
                  <div
                  key={i}
                   dangerouslySetInnerHTML={{__html: h.heading}}
                  />

                ))}  */}
                {productdata.map((t, i) =>
                  productdatashow.slice(0, 1).map((d, i) =>
                    t.id === d.subcategory_id ? (
                      <>
                        <h5>{t.product_name}</h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: t.cat_summery }}
                        />
                      </>
                    ) : (
                      ""
                    )
                  )
                )}

                {/* <Link to="/product" className="link_style">
                  <p className="para_show" onClick={() => setactive(false)}>
                    {" "}
                    View all products
                  </p>
                </Link> */}
              </div>
              <div className="product_divided_show_two">
                <h5>Featured Brands</h5>
                {/* <img src={Brand_one} alt="brand" />
                <img src={Brand_two} alt="brand" /> */}
              </div>
              <div></div>
            </div>
            <div className="divider">
              <Divider />
            </div>
            <div className="product_details_show">
              <div className="bottom_details_show">
                <div className="header_download_div">
                  {productdatashow.map((product, i) => (
                    <Link
                      to={`/productshow${product.link}`}
                      state={product}
                      className="link_style"
                    >
                      <p
                        onClick={() => {
                          Soluction();
                        }}
                        className="url_link"
                      >
                        {product.product_subcategory}
                      </p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`product_show ${active3 ? "active" : ""} `}>
        <div className="product_part">
          <div className="part_one">
            <div className="product_show_list">
              <p className="product_details_style">DOWNLOAD/RESOURCES</p>
            </div>
          </div>
          <div className="part_two">
            <div className="header_download_div">
              {downloade.map((down, i) => (
                <div key={i} className="link_div_show">
                  <p
                    className="url_link"
                    onClick={() => handleOpen(down.image_path7)}
                  >
                    {down.product_name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={`product_show ${active4 ? "active" : ""} `}>
        <div className="product_part">
          <div className="part_one">
            <div className="product_show_list">
              <p className="product_details_style">Leadership Team</p>
            </div>
            <div className="pro_div_style">
              {Team.map((p, i) => (
                <div onClick={() => setSelected(i)} key={i}>
                  <p
                    className={`pro_show ${selected === i ? "underline" : ""}`}
                  >
                    {p}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="part_two">
            <Container maxWidth="lg">
              <p>
                Our team at TurnOn is made up of highly qualified and
                experienced individuals that are committed to offering our
                clients the best IT networking solutions. Our staff works
                arduously to provide our customers with the best service and
                support possible, from product development to customer support.
                Select TurnOn to collaborate with the top team in the IT
                networking sector.
              </p>
              <div className="team_show">
                {data.map((t, i) => (
                  <div className="team_photo" key={i}>
                    <img
                      src={`${photourl}${t.image_path1}`}
                      alt="team"
                      className="team_photo_style"
                    />
                    <div className="para_space_team">
                      <p className="team_para">{t.name}</p>
                      <p className="team_post">{t.position}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          </div>
        </div>
      </div>
      {close ? (
        <div className="mobile_header_show">
          <div className="header_mobile_responsive">
            <Link to="/" className="link_style">
              <p className="para_text_style_mobile" onClick={Closeiconoff}>
                Home
              </p>
            </Link>
            <Link to="/about-us" className="link_style">
              <p className="para_text_style_mobile" onClick={Closeiconoff}>
                About Us
              </p>
            </Link>
            <p className="para_text_style_mobile" onClick={opentabshow}>
              Products
            </p>
            <p
              className="para_text_style_mobile"
              onClick={() => downloadedata()}
            >
              Download/Resources
            </p>
            <p className="para_text_style_mobile" onClick={() => open_Leader()}>
              Leadership Team
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {opentab ? (
        <div className="mobile_header_show">
          <div className="header_mobile_responsive">
            {productdata.map((mv, i) => (
              <Link
                className="link_style"
                to={{
                  pathname: `/product${mv.link}`,
                }}
                state={mv}
              >
                <p
                  className="para_text_style_mobile"
                  key={i}
                  onClick={opentabshowtwo}
                >
                  {mv.product_name}
                </p>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {opentabtwo ? (
        <div className="mobile_header_show">
          <div className="header_mobile_responsive">
            <p className="para_text_style_mobile_link">Category 5e Cables</p>
            <p className="para_text_style_mobile_link">Category 6 Cables</p>
            <p className="para_text_style_mobile_link">Category 6A Cables</p>
            <p className="para_text_style_mobile_link">Category 7 Cables</p>
            <p className="para_text_style_mobile_link">Category 7A Cables</p>
          </div>
        </div>
      ) : (
        ""
      )} */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="cabel_box_style">
            <div>
              <p className="cabel_down_heading">
                Fill in the Form to Download this PDF
              </p>
            </div>
            <div className="cabel_downloade_modal">
              <TextField
                id="outlined-basic"
                label="Name*"
                variant="outlined"
                type="text"
                name="name"
                value={dataform.name}
                onChange={handleChange}
                error={dataform.name.length > 0 ? "" : errorname}
                helperText={dataform.name.length > 0 ? "" : errorname}
                className="text_field_contact"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="cabel_downloade_modal">
              <TextField
                id="outlined-basic"
                label="Email*"
                variant="outlined"
                name="email"
                className="text_field_contact"
                value={dataform.email}
                onChange={handleChange}
                onKeyUp={validateEmail}
                error={dataform.email.length > 0 ? "" : erroremail}
                helperText={dataform.email.length > 0 ? "" : erroremail}
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MarkEmailReadIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {dataform.email > 0 ? (
                ""
              ) : (
                <p className="email_error">{messages}</p>
              )}
            </div>

            <div className="cabel_downloade_modal">
              <TextField
                id="outlined-basic"
                label="Number*"
                variant="outlined"
                type="number"
                name="number"
                value={dataform.number}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={handleChange}
                error={dataform.number.length > 0 ? "" : errorphone}
                helperText={dataform.number.length > 0 ? "" : errorphone}
                className="text_field_contact"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneForwardedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="cabel_downloade_modal">
              <TextField
                id="outlined-basic"
                label="Company Name"
                variant="outlined"
                type="text"
                name="company_name"
                value={dataform.company_name}
                onChange={handleChange}
                // error={data.company_name.length > 0 ? "" : errorcompany}
                // helperText={data.company_namee.length > 0 ? "" : errorcompany}
                className="text_field_contact"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <button className="cabel_dowlnload_button" onClick={Sendetail}>
                Download
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      {downloadepdf ? (
        <div className="team_leader_mobile">
          {downloade.map((down, i) => (
            <>
              <div key={i} className="link_div_show">
                <p
                  className="url_link"
                  onClick={() => handleOpen(down.image_path7)}
                >
                  {down.product_name}
                </p>
              </div>
              <div className="divider">
                <Divider />
              </div>
            </>
          ))}
        </div>
      ) : (
        ""
      )}

      {openleader ? (
        <div className="team_leader_mobile">
          <p>
            Our team at TurnOn is made up of highly qualified and experienced
            individuals that are committed to offering our clients the best IT
            networking solutions. Our staff works arduously to provide our
            customers with the best service and support possible, from product
            development to customer support. Select TurnOn to collaborate with
            the top team in the IT networking sector.
          </p>
          <div className="team_show">
            {data.map((t, i) => (
              <div className="team_photo" key={i}>
                <img
                  src={`${photourl}${t.image_path1}`}
                  alt="team"
                  className="team_photo_style"
                />
                <div className="para_space_team">
                  <p className="team_para">{t.name}</p>
                  <p className="team_post">{t.position}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Header;
