import React, { useEffect } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import http from "../../config/http";
import Logo_ from "../../assets/images/Turn-On-Logo.png";


function Videoclip() {
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/";

  useEffect(() => {
    http.get("homepagevideo").then((response) => {
      // console.log(response.data.video);
      setdata(response.data.video);
    });
  }, []);

  return (
    <div className="videoclip">
      {data.map((v, i) => (
        <div Key={i}>
          <video
            src={`${photourl}${v.video_path}`}
            autoPlay
            muted
            loop
            className="video_play"
          ></video>
          <div>
            <Modal
              disableEnforceFocus
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="box_style_modal">
                <Video
                  poster={Logo_}
                  controls={[
                    "PlayPause",
                    "Seek",
                    "Time",
                    "Volume",
                    "Fullscreen",
                  ]}
                  onCanPlayThrough={() => {
                    console.log("play");
                  }}
                >
                  <source src={`${photourl}${v.video_path2}`} type="video/mp4" />
                </Video>
              </Box>
            </Modal>
          </div>
          <Container maxWidth="xl">
            <div className="banner_test_left_one">
              <p className="our_misson_para_one"
                dangerouslySetInnerHTML={{__html: v.textarea}}
              />
              <div>
                <button className="homepage_button_two" onClick={handleOpen}>
                  Play Video
                </button>
              </div>
            </div>
          </Container>
        </div>
      ))}
    </div>
  );
}

export default Videoclip;
