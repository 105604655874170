import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import About from "../../assets/images/about-us.jpg";
import People from "../../assets/images/921240-2-people-blue-grad-icon-wide-90.webp";
import Place from "../../assets/images/921241-smart-city-blue-grad-icon-wide-90.webp";
import Singal from "../../assets/images/921242-signal-blue-grad-icon-wide-90.webp";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Briefcase from "../../assets/images/briefcase.webp";
import Globe_White from "../../assets/images/globe-white.webp";
import People_White from "../../assets/images/people-white.webp";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import http from "../../config/http";
import Logo from "../../assets/images/Turn-On-Logo.png";

const About_us = () => {
  const [aboutus, setaboutus] = React.useState([]);
  const [aboutusper, setaboutusper] = React.useState([]);
  const [avoutvideo, setavoutvideo] = React.useState([]);
  const [avideo, setavideo] = React.useState("");

  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/";

  useEffect(() => {
    window.scrollTo(0, 0);
    http.get("aboutusdata").then((response) => {
      // console.log(response.data.aboutus);
      setaboutus(response.data.aboutus);
    });
    http.get("refferingussdata").then((response) => {
      // console.log(response.data.data);
      setaboutusper(response.data.data);
    });
    http.get("aboutusvideo").then((response) => {
      console.log(response.data);
      setavoutvideo(response.data);
    });
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (i) => {
    setOpen(true);
    setavideo(i);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      {aboutus.map((about, i) => (
        <>
          <div
            style={{
              backgroundImage: `url(${About})`,
              height: "350px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container maxWidth="xl">
              <div>
                <h1 className="about_us_style">Get to know us</h1>
              </div>
            </Container>
          </div>
          <Container maxWidth="xl">
            <>
              <div className="about_us_div_two">
                <div
                  className="about_us_now"
                  dangerouslySetInnerHTML={{
                    __html: about.about_our_company,
                  }}
                />
                {/* <Container maxWidth="sm"> */}
                <div
                  className="about_us_para"
                  dangerouslySetInnerHTML={{
                    __html: about.vision,
                  }}
                />

                <div
                  className="about_us_para"
                  dangerouslySetInnerHTML={{
                    __html: about.mission,
                  }}
                />
                {/* </Container> */}
              </div>
              <div className="about_us_div_box_show">
                <h5 className="about_us_how">How we help</h5>
                <h3 className="about_us_note">
                  We innovate end-to-end solutions between people, places and
                  networks.
                </h3>
                <div className="about_us_div_flex">
                  <div className="about_us_box">
                    <img src={People} alt="people" className="img_about" />
                    <h5 className="about_us_box_div_para">People</h5>
                    <p className="about_us_box_div_para1">
                      Ensuring the ability to confidently
                      <br />
                      connect to what matter
                    </p>
                  </div>
                  <div className="about_us_box">
                    <img src={Place} alt="people" className="img_about" />
                    <h5 className="about_us_box_div_para">Place</h5>
                    <p className="about_us_box_div_para1">
                      Enabling smart buildings,
                      <br />
                      cities and campuses
                    </p>
                  </div>
                  <div className="about_us_box">
                    <img src={Singal} alt="people" className="img_about" />
                    <h5 className="about_us_box_div_para">Network</h5>
                    <p className="about_us_box_div_para1">
                      Providing leading solutions that
                      <br />
                      solve for tomorrow’s possibilities
                    </p>
                  </div>
                </div>
              </div>
            </>
            <div className="about_us_brand">
              <p className="about_us_para">Featured Brands</p>
              <h3 className="about_us_note">
                Continuing to innovate to bring you the world's leading
                portfolio of networking solutions.
              </h3>
              <div className="about_us_div_flex">
                {avoutvideo.slice(0, 3).map((v, i) => (
                  <div className="about_us_video_div" key={i}>
                    <img src={Logo} alt="people" className="img_about_1" />
                    <div
                      className="about_us_video_icon"
                      onClick={() => handleOpen(v.video_path)}
                    >
                      <PlayCircleFilledWhiteIcon />
                    </div>
                    <div className="about_us_video_para">
                      <p
                        className="about_us_box_div_para2"
                        dangerouslySetInnerHTML={{
                          __html: v.video_description,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <Modal
                  disableEnforceFocus
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="box_style_modal_video">
                    <Video
                      className="video_style_box"
                      poster={Logo}
                      controls={[
                        "PlayPause",
                        "Seek",
                        "Time",
                        "Volume",
                        "Fullscreen",
                      ]}
                      onCanPlayThrough={() => {
                        console.log("play");
                      }}
                    >
                      <source src={`${photourl}${avideo}`} type="video/mp4" />
                    </Video>
                  </Box>
                </Modal>
              </div>
            </div>
          </Container>
          <div className="about_us_ratio">
            <Container>
              <div className="margin_about_us">
                <p className="text_white about_us_para ">Rely on TurnOn</p>
                <h3 className="about_us_note text_white">
                  Our proven experience means you can feel confident in working
                  with us
                </h3>
                <div className="about_us_div_flex">
                  <div className="about_us_box_two">
                    <img src={Briefcase} alt="people" className="img_about" />
                    <h3 className="about_us_note text_white margin_zero">
                      {about.customer_no}
                    </h3>
                    <p className="text_white ">Years of network experience</p>
                  </div>
                  <div className="about_us_box_two">
                    <img src={Globe_White} alt="people" className="img_about" />
                    <h3 className="about_us_note text_white margin_zero">
                      {about.industries_served}
                    </h3>
                    <p className="text_white ">
                      Global patents and patent applications
                    </p>
                  </div>
                  <div className="about_us_box_two">
                    <img
                      src={People_White}
                      alt="people"
                      className="img_about"
                    />
                    <h3 className="about_us_note text_white margin_zero">
                      {about.completed_projects}
                    </h3>
                    <p className="text_white ">
                      Installation partners and hundreds of TurnOn field
                      application engineers
                    </p>
                  </div>
                  <div className="about_us_box_two">
                    <img
                      src={People_White}
                      alt="people"
                      className="img_about"
                    />
                    <h3 className="about_us_note text_white margin_zero">
                      {about.awards_no}
                    </h3>
                    <p className="text_white ">Winning Awards</p>
                  </div>
                </div>
                <div className="about_us_divider_hide">
                  <Divider />
                </div>
                {aboutusper.map((p, i) => (
                  <div className="about_us_ratio_show" key={i}>
                    <div className="divided_about_us_screen">
                      <div
                        className="text_white"
                        dangerouslySetInnerHTML={{ __html: p.textarea }}
                      />
                    </div>
                    <div className="divided_about_us_screen">
                      <div className="about_progress_bar_top">
                        <div className="percentage_show">
                          <p className="progress_bar_about">
                            Standard Products
                          </p>
                          <p className="progress_bar_about">
                            {p.standard_products} %
                          </p>
                        </div>
                        <Slider
                          disabled
                          defaultValue={p.standard_products}
                          aria-label="Disabled slider"
                        />
                      </div>
                      <div className="about_progress_bar_top">
                        <div className="percentage_show">
                          <p className="progress_bar_about">
                            Innovative Solutions
                          </p>
                          <p className="progress_bar_about">
                            {p.innovative_solutions} %
                          </p>
                        </div>
                        <Slider
                          disabled
                          defaultValue={p.innovative_solutions}
                          aria-label="Disabled slider"
                        />
                      </div>
                      <div className="about_progress_bar_top">
                        <div className="percentage_show">
                          <p className="progress_bar_about">
                            Reasonable pricing
                          </p>
                          <p className="progress_bar_about">
                            {p.reasonable_pricing} %
                          </p>
                        </div>
                        <Slider
                          disabled
                          defaultValue={p.reasonable_pricing}
                          aria-label="Disabled slider"
                        />
                      </div>
                      <div className="about_progress_bar_top">
                        <div className="percentage_show">
                          <p className="progress_bar_about">
                            Excellent customer service
                          </p>
                          <p className="progress_bar_about">
                            {p.excellent_customer_service} %
                          </p>
                        </div>
                        <Slider
                          disabled
                          defaultValue={p.excellent_customer_service}
                          aria-label="Disabled slider"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          </div>
        </>
      ))}
    </>
  );
};
export default About_us;
