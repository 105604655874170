import Videoclip from "../../components/videoclip/Videoclip";
import Banner from "../../components/bannerimage/Banner";
import Cardshow from "../../components/cardshow/Cardshow";
 import React, {useEffect} from 'react';

function Home() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <>
      <Videoclip />
       <Banner />
      <Cardshow /> 
    </>
  );
}

export default Home;
