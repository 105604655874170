import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import PinDropIcon from "@mui/icons-material/PinDrop";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import Contact_Image from "../../assets/images/home3-slider.jpg";
import http from "../../config/http";
import { ToastContainer, toast } from "react-toastify";

const notify = (message) => toast(message);

export default function Contact_Us() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [contactdata, setcontactdata] = useState([]);
  const [errorname, setErrorname] = useState("");
  const [errorlastname, setErrorlastname] = useState("");
  const [erroremail, setErroremail] = useState("");
  const [errorphone, setErrorphone] = useState("");
  const [messages, setMessages] = useState("");

  useEffect(() => {
    http.get("contactus").then((response) => {
      // console.log(response.data.data);
      setcontactdata(response.data.data);
    });
  }, []);

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
    phone: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validateEmail = () => {
    if (data.email.length <= 0) {
    } else {
      if (regex.test(data.email)) {
        setMessages("");
      } else {
        setMessages("Please enter a valid email!");
      }
    }
  };

  const Sendcontact = () => {
    if (data.firstname.length <= 0) {
      setErrorname("Firstname Required");
    } else if (data.lastname.length <= 0) {
      setErrorlastname("Lastname Required");
    } else if (data.email.length <= 0) {
      setErroremail("Email Required");
    } else if (data.phone.length <= 0) {
      setErrorphone("Phone Required");
    } else {
      let formData = new FormData();
      for (const property in data) {
        formData.append(property, data[property]);
        // console.log(formData);
      }
      http.post("contactusform", formData).then((response) => {
        if (response.status === 200) {
          notify(response.data.message);
          setData({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            message: "",
          });
        }
      });
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} theme="light" />
      <div
        className="contact_div"
        style={{
          backgroundImage: `url(${Contact_Image})`,
          height: "220px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="contact_text">
          <Container maxWidth="xl">
            <h1 className="text_white">Contact Us</h1>
          </Container>
        </div>
      </div>
      <div className="contact_us_margin_show">
        <Container maxWidth="xl">
          <div className="contact_info">
            <div className="contact_info_detail">
              <div className="contact_input_field"></div>
              <div className="input_contect_form">
                <div className="input_contact_margin">
                  <p className="icon_para_input">Send a Messgae</p>
                </div>
                <div className="space_contact_show">
                  <div className="contact_margin_space">
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      type="text"
                      name="firstname"
                      value={data.firstname}
                      onChange={handleChange}
                      className="text_field_contact"
                      required
                      error={data.firstname.length > 0 ? "" : errorname}
                      helperText={data.firstname.length > 0 ? "" : errorname}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className="contact_margin_space">
                    <TextField
                      id="outlined-basic"
                      label="Last name"
                      variant="outlined"
                      name="lastname"
                      className="text_field_contact"
                      value={data.lastname}
                      onChange={handleChange}
                      helperText={data.lastname.length > 0 ? "" : errorlastname}
                      error={data.lastname.length > 0 ? "" : errorlastname}
                      type="text"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="space_contact_show">
                  <div className="contact_margin_space">
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      name="email"
                      className="text_field_contact"
                      value={data.email}
                      helperText={data.email.length > 0 ? "" : erroremail}
                      error={data.email.length > 0 ? "" : erroremail}
                      onChange={handleChange}
                      onKeyDown={validateEmail}
                      type="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MarkEmailReadIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {data.email > 0 ? (
                      ""
                    ) : (
                      <p className="message_error">{messages}</p>
                    )}
                  </div>

                  <div className="contact_margin_space">
                    <TextField
                      id="outlined-basic"
                      label="Number"
                      variant="outlined"
                      type="number"
                      name="phone"
                      value={data.phone}
                      onChange={handleChange}
                      className="text_field_contact"
                      helperText={data.phone.length > 0 ? "" : errorphone}
                      error={data.phone.length > 0 ? "" : errorphone}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneForwardedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="space_contact_show">
                  <i className="bi bi-textarea"></i>
                  <textarea
                    cols={54}
                    rows={7}
                    onChange={handleChange}
                    name="message"
                    value={data.message}
                    placeholder="Write a message..."
                    className="contact_areatext"
                  />
                </div>
                <div className="contact_buttom_center">
                  <button className="contact_button" onClick={Sendcontact}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="contact_divided">
              <p className="contact_info_text">Contact Info</p>
              {contactdata.map((c, i) => (
                <div className="contact_icon" key={i}>
                  <div className="contect_icon_show">
                    <PinDropIcon />
                    <div
                      className="icon_para"
                      dangerouslySetInnerHTML={{
                        __html: c.location,
                      }}
                    />
                  </div>
                  <div className="contect_icon_show">
                    <MarkEmailReadIcon />
                    <div
                      className="icon_para"
                      dangerouslySetInnerHTML={{
                        __html: c.email,
                      }}
                    />
                  </div>
                  <div className="contect_icon_show">
                    <PhoneForwardedIcon />
                    <div
                      className="icon_para"
                      dangerouslySetInnerHTML={{
                        __html: c.mob_nmbr,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
