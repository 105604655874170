import React, { useEffect } from "react";
import ProductImage from "../../assets/images/product.jpg";
import Container from "@mui/material/Container";
import { useState } from "react";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { Link, useParams } from "react-router-dom";
import http from "../../config/http";
import { useContext } from "react";
import { MyContext } from "../../MyContext";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import usePagination from "../pagination";
const notify = (message) => toast(message);

export default function Product() {
  const { text } = useContext(MyContext);
  const params = useParams();
  const [productdatashow, setproductdatashow] = useState([]);
  const [downloade, setdownloade] = useState([]);
  const [data, setData] = useState({
    name: "",
    email: "",
    number: "",
    company_name: "",
  });
  const [page, setPage] = React.useState(1);
  const [errorname, setErrorname] = useState("");
  const [file, setfile] = useState("");
  const [erroremail, setErroremail] = useState("");
  const [errorphone, setErrorphone] = useState("");
  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/upload/";
  const [messages, setMessages] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    setOpen(true);
    setfile(e);
  };
  const handleClose = () => setOpen(false);
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validateEmail = () => {
    if (data.email.length <= 0) {
    } else {
      if (regex.test(data.email)) {
        setMessages("");
      } else {
        setMessages("Please enter a valid email!");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    http
      .get(`product-categories/${params.id}/subcategories`)
      .then((response) => {
        console.log(response.data);
        setproductdatashow(response.data);
      });
    http.get(`productdetaildownload`).then((response) => {
      // console.log(response.data);
      setdownloade(response.data);
    });
  }, [params.id]);

  const handlePrint = () => {
    window.open(`${photourl}${file}`, "PRINT");
  };

  const Sendetail = () => {
    if (data.name.length <= 0) {
      setErrorname("Name Required");
    } else if (data.email.length <= 0) {
      setErroremail("Email Required");
    } else if (data.number.length <= 0) {
      setErrorphone("Number Required");
    } else {
      let formData = new FormData();
      for (const property in data) {
        formData.append(property, data[property]);
        console.log(formData);
      }
      http.post(`productdetaildownloadandform`, formData).then((response) => {
        if (response.status === 200) {
          notify(response.data.message);
          setData({
            name: "",
            email: "",
            number: "",
            company_name: "",
          });
          handleClose();
          handlePrint();
        }
      });
    }
  };
  const PER_PAGE = 10;
  const count = Math.ceil(downloade.length / PER_PAGE);
  const _DATA = usePagination(downloade, PER_PAGE);
  const handleChangepage = (event, value) => {
    setPage(value);
    _DATA.jump(value);
  };

  return (
    <div className="product_main">
      <ToastContainer position="top-right" autoClose={5000} theme="light" />
      <div
        className="product_bg_image"
        style={{
          backgroundImage: `url(${ProductImage})`,
          height: "240px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="contact_text">
          <div className="product_bg">
            {text.map((name, i) =>
              name.id === params.id ? <h1>{name.product_name}</h1> : ""
            )}
          </div>
        </div>
      </div>
      <div className="cabel_margin">
        <Container maxWidth="xl">
          <div className="cabel_div_main">
            {productdatashow.map((product, i) => (
              <div className="cabel_div_show_" key={i}>
                <Link
                  to={{
                    pathname: `/productshow${product.link}`,
                  }}
                  state={product}
                >
                  <div className="cabel_divided_div">
                    <div className="cabel_div">
                      <img
                        src={`${photourl}${product.image_path1}`}
                        alt="cable"
                        className="cable_image_show"
                      />
                    </div>
                    <div>
                      <h2 className="cabel_name_">
                        {product.product_subcategory}
                      </h2>
                    </div>
                    {/* <div className="cabel_product_name" onClick={openBox}>
                    <p className="cabel_name_para">Product (2)</p>
                    {opendiv ? (
                      <KeyboardArrowUpOutlinedIcon />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon />
                    )}
                  </div> */}
                    {/* {opendiv ? (
                    <div className="open_cabel_div">
                      <Link to="/productdetail" className="link_style">
                        <p className="open_cabel_para">
                          Coaxial cable-in-conduit
                        </p>
                      </Link>
                      <p className="open_cabel_para">
                        Coaxial fiber cable-in-conduit
                      </p>
                    </div>
                  ) : (
                    ""
                  )} */}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <Container maxWidth="xl">
        <div className="h2_cabel">
          <h2>Product</h2>
        </div>
        <div className="cabel_downloade">
          {_DATA.currentData().map((down, i) => (
            <div className="cabel_downloade_box" key={i}>
              <div className="cabel_down_">
                <div className="cabel_downloade_div">
                  <img
                    src={`${photourl}${down.image_path1}`}
                    alt="down"
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <p className="cabel_base_para">Base Product</p>
                  <p className="cabel_product_name_down">{down.product_name}</p>
                  <p
                    className="cabel_product_detail"
                    dangerouslySetInnerHTML={{ __html: down.product_short_des }}
                  />
                </div>
                <div
                  className="cabel_down_space"
                  onClick={() => handleOpen(down.image_path7)}
                >
                  <div className="cabel_space_border">
                    <SimCardDownloadOutlinedIcon />
                    <p className="cabel_specification">Specifications</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="cabel_box_style">
              <div>
                <p className="cabel_down_heading">
                  Fill in the Form to Download this PDF
                </p>
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Name*"
                  variant="outlined"
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  error={data.name.length > 0 ? "" : errorname}
                  helperText={data.name.length > 0 ? "" : errorname}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Email*"
                  variant="outlined"
                  name="email"
                  className="text_field_contact"
                  value={data.email}
                  onChange={handleChange}
                  onKeyUp={validateEmail}
                  error={data.email.length > 0 ? "" : erroremail}
                  helperText={data.email.length > 0 ? "" : erroremail}
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MarkEmailReadIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {data.email > 0 ? (
                  ""
                ) : (
                  <p className="email_error">{messages}</p>
                )}
              </div>

              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Number*"
                  variant="outlined"
                  type="number"
                  name="number"
                  value={data.number}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={handleChange}
                  error={data.number.length > 0 ? "" : errorphone}
                  helperText={data.number.length > 0 ? "" : errorphone}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneForwardedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Company Name"
                  variant="outlined"
                  type="text"
                  name="company_name"
                  value={data.company_name}
                  onChange={handleChange}
                  // error={data.company_name.length > 0 ? "" : errorcompany}
                  // helperText={data.company_namee.length > 0 ? "" : errorcompany}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <button className="cabel_dowlnload_button" onClick={Sendetail}>
                  Download
                </button>
              </div>
            </Box>
          </Modal>
        </div>
        <div className="pagination-center">
          <Stack spacing={2}>
            <Pagination
              count={count}
              page={page}
              onChange={handleChangepage}
              color="primary"
            />
          </Stack>
        </div>
      </Container>
    </div>
  );
}
