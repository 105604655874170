import React, { useEffect } from "react";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { useState } from "react";
import Container from "@mui/material/Container";
import { useParams } from "react-router-dom";
import http from "../../config/http";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { ToastContainer, toast } from "react-toastify";

const notify = (message) => toast(message);

export default function Productshow() {
  const params = useParams();
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState("");
  const [downloade, setdownloade] = useState("");
  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/";
  const [dataform, setDataform] = useState({
    name: "",
    email: "",
    number: "",
    company_name: "",
  });
  const [image, setimage] = useState("");
  const [messages, setMessages] = useState("");
  const [errorname, setErrorname] = useState("");
  const [erroremail, setErroremail] = useState("");
  const [errorphone, setErrorphone] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    setDataform({ ...dataform, [e.target.name]: e.target.value });
  };
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const validateEmail = () => {
    if (dataform.email.length <= 0) {
    } else {
      if (regex.test(dataform.email)) {
        setMessages("");
      } else {
        setMessages("Please enter a valid email!");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    http
      .get(
        `product-subcategories/${params.id}/${params.productname}/${params.productsubcategory}/dynamic-products`
      )
      .then((response) => {
        setdata1(response.data[0].image_path1);
        setdata(response.data);
        setdownloade(response.data[0].image_path7);
      });
  }, [params.id, params.productname, params.productsubcategory]);

  const handlePrint = () => {
    window.open(`${photourl}${downloade}`, "PRINT");
  };
  const Sendetail = () => {
    if (dataform.name.length <= 0) {
      setErrorname("Name Required");
    } else if (dataform.email.length <= 0) {
      setErroremail("Email Required");
    } else if (dataform.number.length <= 0) {
      setErrorphone("Number Required");
    } else {
      let formData = new FormData();
      for (const property in dataform) {
        formData.append(property, dataform[property]);
        // console.log(formData);
      }
      http.post(`productdetaildownloadandform`, formData).then((response) => {
        if (response.status === 200) {
          notify(response.data.message);
          setDataform({
            name: "",
            email: "",
            number: "",
            company_name: "",
          });
          handleClose();
          handlePrint();
        }
      });
    }
  };
  const SelectImage = (e) => {
    setimage(e.target.currentSrc);
  };
  return (
    <div className="product_show_main">
      <ToastContainer position="top-right" autoClose={5000} theme="light" />

      <Container maxWidth="xl">
        {data.map((d, i) => (
          <>
            <div className="product_showimage">
              <div className="product_show_part_one">
                <div className="product_image_slide">
                  {image.length > 0 ? (
                    <img src={`${image}`} alt="img" className="show_pro_img" />
                  ) : (
                    <img
                      src={`${photourl}${data1}`}
                      alt="img"
                      className="show_pro_img"
                    />
                  )}
                </div>
                <div className="product_image_slide_center">
                  <img
                    src={`${photourl}${d.image_path1}`}
                    alt="imh"
                    className="image_product_array"
                    onClick={(e) => SelectImage(e)}
                  />
                  <img
                    src={`${photourl}${d.image_path2}`}
                    alt="imh"
                    className="image_product_array"
                    onClick={(e) => SelectImage(e)}
                  />
                  <img
                    src={`${photourl}${d.image_path3}`}
                    alt="imh"
                    className="image_product_array"
                    onClick={(e) => SelectImage(e)}
                  />
                  <img
                    src={`${photourl}${d.image_path4}`}
                    alt="imh"
                    className="image_product_array"
                    onClick={(e) => SelectImage(e)}
                  />
                </div>
              </div>
              <div className="product_show_part_two">
                <div className="heading">
                  <h1>{d.product_name}</h1>
                  <p
                    dangerouslySetInnerHTML={{ __html: d.product_short_des }}
                  />
                </div>
                <div>
                  <p className="product_specification">Specifications</p>
                  <div className="cabel_space_border_two" onClick={handleOpen}>
                    <SimCardDownloadOutlinedIcon />
                    <p className="cabel_specification">Download</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="pro_description">Product Description</p>
              <div dangerouslySetInnerHTML={{ __html: d.product_des }} />
            </div>
          </>
        ))}
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="cabel_box_style">
              <div>
                <p className="cabel_down_heading">
                  Fill in the Form to Download this PDF
                </p>
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Name*"
                  variant="outlined"
                  type="text"
                  name="name"
                  value={dataform.name}
                  onChange={handleChange}
                  error={dataform.name.length > 0 ? "" : errorname}
                  helperText={dataform.name.length > 0 ? "" : errorname}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Email*"
                  variant="outlined"
                  name="email"
                  className="text_field_contact"
                  value={dataform.email}
                  onChange={handleChange}
                  onKeyUp={validateEmail}
                  error={dataform.email.length > 0 ? "" : erroremail}
                  helperText={dataform.email.length > 0 ? "" : erroremail}
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MarkEmailReadIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {dataform.email > 0 ? (
                  ""
                ) : (
                  <p className="email_error">{messages}</p>
                )}
              </div>

              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Number*"
                  variant="outlined"
                  type="number"
                  name="number"
                  value={dataform.number}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  error={dataform.number.length > 0 ? "" : errorphone}
                  helperText={dataform.number.length > 0 ? "" : errorphone}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneForwardedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Company Name"
                  variant="outlined"
                  type="text"
                  name="company_name"
                  value={dataform.company_name}
                  onChange={handleChange}
                  // error={data.company_name.length > 0 ? "" : errorcompany}
                  // helperText={data.company_namee.length > 0 ? "" : errorcompany}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <button className="cabel_dowlnload_button" onClick={Sendetail}>
                  Download
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      </Container>
    </div>
  );
}
