import React, { useEffect } from "react";
import CabelBanner from "../../assets/images/outdoorcable-banner.jpg";
import Pdf from "../../assets/video/OTS.pdf";
import Download from "../../assets/images/down.webp";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
export default function Productdetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePrint = (event) => {
    event.preventDefault();
    window.open(Pdf, "PRINT");
  };
  return (
    <div className="product_detail">
      <div
        className="product_bg_image"
        style={{
          backgroundImage: `url(${CabelBanner})`,
          height: "240px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="contact_text">
          <div className="product_bg">
            <h1>Cable-In-Conduit</h1>
          </div>
        </div>
      </div>
      <Container maxWidth="xl">
        <div className="cabel_margin">
          To help speed deployment where outside plant buried cables are
          vulnerable to critters, harsh environmental conditions or neighborhood
          digging, CommScope provides a cable-in-conduit (CIC) solution. We
          offer the ability to pre-install any of our coaxial or fiber cables
          inside our high-density polyethylene (HDPE) continuous conduit to
          simplify installations and save on expenditures by purchasing only
          what is needed. Our conduits are available in a multitude of diameters
          ranging from 3/4 to 2 inches and feature either a smooth or
          ribbed-wall design. In addition, these conduits can also include an
          embedded tone wire within the walls to make locating buried cable
          easier in the future.
        </div>
      </Container>
      <Container maxWidth="xl">
        <div className="h2_cabel">
          <h2>Product</h2>
        </div>
        <div className="cabel_downloade">
          <div className="cabel_downloade_box">
            <div className="cabel_down_">
              <div className="cabel_downloade_div">
                <img src={Download} alt="down" style={{ width: "100%" }} />
              </div>
              <div>
                <Link to="/productshow" className="link_style">
                  <p className="cabel_product_name_down">
                    3020008 | 050T110F677TSEF XP
                  </p>
                </Link>
                <p className="cabel_product_detail">
                  ConQuest® Cable in Conduit, 1/2 in, SDR 11, terracotta
                  (F677TSEF)
                </p>
              </div>
              <div className="cabel_down_space" onClick={handleOpen}>
                <div className="cabel_space_border">
                  <SimCardDownloadOutlinedIcon />
                  <p className="cabel_specification">Specifications</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cabel_downloade_box">
            <div className="cabel_down_">
              <div className="cabel_downloade_div">
                <img src={Download} alt="down" style={{ width: "100%" }} />
              </div>
              <div>
                <p className="cabel_base_para">Base Product</p>
                <p className="cabel_product_name_down">1071E</p>
                <p className="cabel_product_detail">
                  GigaSPEED XL® 1071E ETL Verified Category 6 U/UTP Cable
                </p>
              </div>
              <div className="cabel_down_space" onClick={handleOpen}>
                <div className="cabel_space_border">
                  <SimCardDownloadOutlinedIcon />
                  <p className="cabel_specification">Specifications</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cabel_downloade_box">
            <div className="cabel_down_">
              <div className="cabel_downloade_div">
                <img src={Download} alt="down" style={{ width: "100%" }} />
              </div>
              <div>
                <p className="cabel_base_para">Base Product</p>
                <p className="cabel_product_name_down">1071E</p>
                <p className="cabel_product_detail">
                  GigaSPEED XL® 1071E ETL Verified Category 6 U/UTP Cable
                </p>
              </div>
              <div className="cabel_down_space" onClick={handleOpen}>
                <div className="cabel_space_border">
                  <SimCardDownloadOutlinedIcon />
                  <p className="cabel_specification">Specifications</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cabel_downloade_box">
            <div className="cabel_down_">
              <div className="cabel_downloade_div">
                <img src={Download} alt="down" style={{ width: "100%" }} />
              </div>
              <div>
                <p className="cabel_base_para">Base Product</p>
                <p className="cabel_product_name_down">1071E</p>
                <p className="cabel_product_detail">
                  GigaSPEED XL® 1071E ETL Verified Category 6 U/UTP Cable
                </p>
              </div>
              <div className="cabel_down_space" onClick={handleOpen}>
                <div className="cabel_space_border">
                  <SimCardDownloadOutlinedIcon />
                  <p className="cabel_specification">Specifications</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cabel_downloade_box">
            <div className="cabel_down_">
              <div className="cabel_downloade_div">
                <img src={Download} alt="down" style={{ width: "100%" }} />
              </div>
              <div>
                <p className="cabel_base_para">Base Product</p>
                <p className="cabel_product_name_down">1071E</p>
                <p className="cabel_product_detail">
                  GigaSPEED XL® 1071E ETL Verified Category 6 U/UTP Cable
                </p>
              </div>
              <div className="cabel_down_space" onClick={handleOpen}>
                <div className="cabel_space_border">
                  <SimCardDownloadOutlinedIcon />
                  <p className="cabel_specification">Specifications</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="cabel_box_style">
              <div>
                <p className="cabel_down_heading">
                  Fill in the Form to Download this PDF
                </p>
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  type="text"
                  name="name"
                  // value=""
                  // onChange={handleChange}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  name="email"
                  className="text_field_contact"
                  // value={data.email}
                  // onChange={handleChange}
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MarkEmailReadIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Number"
                  variant="outlined"
                  type="number"
                  name="phone"
                  // value={data.phone}
                  // onChange={handleChange}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneForwardedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="cabel_downloade_modal">
                <TextField
                  id="outlined-basic"
                  label="Company Name"
                  variant="outlined"
                  type="text"
                  name="company name"
                  // value=""
                  // onChange={handleChange}
                  className="text_field_contact"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <button
                  className="cabel_dowlnload_button"
                  onClick={handlePrint}
                >
                  Download
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      </Container>
    </div>
  );
}
