import React, { useState } from "react";
import "./App.css";
import Main from "./config/routes";
// import "antd/dist/antd.css";
import { MyContext } from "./MyContext";

import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  const [text, setText] = useState([]);
  const [downloade_data, setdownloade_data] = useState([]);


  return (
    <div>
      <MyContext.Provider
        value={{
          text,
          setText,
          setdownloade_data,
          downloade_data,
        }}
      >
        <Main />
      </MyContext.Provider>
    </div>
  );
}

export default App;
