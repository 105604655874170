import React, { useEffect, useState } from "react";
import BannerImage from "../../assets/images/960965-nmn-1.webp";
import BannerImagetwo from "../../assets/images/960958-dig-soc-1.webp";
import Container from "@mui/material/Container";
import BannerImagethree from "../../assets/images/960961-dig-soc-3.webp";
import BannerImagefure from "../../assets/images/960963-nmn-3.webp";
import http from "../../config/http";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import { Link } from "react-router-dom";

function Banner() {
  const [data, setdata] = useState([]);
  const photourl = "https://www.turnon.co.in/turnon/website/webmaster/upload/";

  useEffect(() => {
    http.get("servicesandexperience").then((response) => {
      // console.log(response.data.data);
      setdata(response.data.data);
    });
  }, []);
  return (
    <>
      <div className="banner">
        <div
          style={{
            backgroundImage: `url(${BannerImage})`,
            height: "600px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="banner_not_show"
        >
          <Container maxWidth="xl">
            <div className="banner_test_left">
              <p className="ourmissin_text">Our mission</p>
              <p className="now_meet">Now meets next</p>
              <p className="our_misson_para">
                Turnon people and solutions redefine what’s possible from
                connectivity all over the world, helping our network partners
                solve their most pressing challenges today—and innovating better
                ways for them to solve for what’s next
              </p>
              <div>
                <Link to="/about-us" className="parttwo_button">
                  <button className="homepage_button">TurnOn Mission</button>
                </Link>
              </div>
            </div>
          </Container>
        </div>
        <div
          style={{
            backgroundImage: `url(${BannerImagefure})`,
            height: "600px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="banner_show_mobile"
        >
          {" "}
        </div>
        <Container maxWidth="xl">
          <div className="banner_test_left_mobile">
            <p className="ourmissin_text">Our mission</p>
            <p className="now_meet">Now meets next</p>
            <p className="our_misson_para">
              Turnon people and solutions redefine what’s possible from
              connectivity all over the world, helping our network partners
              solve their most pressing challenges today—and innovating better
              ways for them to solve for what’s next
            </p>
            <div>
              <Link to="/about-us" className="parttwo_button">
                <button className="homepage_button"> TurnOn Mission</button>
              </Link>
            </div>
          </div>
        </Container>
      </div>

      <div className="banner_two">
        <div
          style={{
            backgroundImage: `url(${BannerImagetwo})`,
            height: "600px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="banner_not_show"
        >
          <Container maxWidth="xl">
            <div className="banner_second_text">
              <div className="banner_width">
                <p className="ourmissin_text">In Focus</p>
                <p className="now_meet">Building a digital society</p>
                <p className="our_misson_para">
                  We’re part of the global effort to connect and empower people
                  everywhere.
                </p>
                <div>
                  <button className="homepage_button">Discover how</button>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div
          style={{
            backgroundImage: `url(${BannerImagethree})`,
            height: "600px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="banner_show_mobile"
        >
          {" "}
        </div>
        <Container maxWidth="xl">
          <div className="banner_second_text_mobile">
            <div className="banner_width">
              <p className="ourmissin_text">In Focus</p>
              <p className="now_meet">Building a digital society</p>
              <p className="our_misson_para">
                We’re part of the global effort to connect and empower people
                everywhere.
              </p>
              <div>
                <button className="homepage_button">Discover how</button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        {data.map((w, i) => (
          <div className="about_choose_us" key={i}>
            <div
              className="about_us_now"
              dangerouslySetInnerHTML={{ __html: w.heading }}
            />
            <div className="about_choose_us_div">
              <div className="choose_partone">
                <div className="part_onehalf">
                  <div className="icon_circle">
                    <img src={`${photourl}${w.image_path1}`} alt="prize" />
                  </div>
                  <div className="icon_heading_top">
                    <div
                      className="icon_heading"
                      dangerouslySetInnerHTML={{ __html: w.standard_product }}
                    />
                  </div>
                </div>
                <div className="part_twohalf">
                  <div className="icon_circle">
                    <img src={`${photourl}${w.image_path2}`} alt="prize" />
                  </div>
                  <div className="icon_heading_top">
                    <div
                      className="icon_heading"
                      dangerouslySetInnerHTML={{ __html: w.reasonable_pricing }}
                    />
                  </div>
                </div>
              </div>
              <div className="choose_parttwo">
                <div className="dotted_desigen">
                  <h2 className="para_text">
                    {w.year_experience}+ Years Experience
                  </h2>
                  <div className="div_para">
                    Dedication to quality, innovation, and Customer service is
                    our Commitment.
                  </div>
                  <div style={{ marginTop: "3rem" }}>
                    <div className="tick_para">
                      <DoneTwoToneIcon />
                      <p className="tick_done_style">Standard Products</p>
                    </div>
                    <div className="tick_para">
                      <DoneTwoToneIcon />
                      <p className="tick_done_style">Innovative Solutions </p>
                    </div>
                    <div className="tick_para">
                      <DoneTwoToneIcon />
                      <p className="tick_done_style">Reasonable Pricing </p>
                    </div>
                    <div className="tick_para">
                      <DoneTwoToneIcon />
                      <p className="tick_done_style">Customer Services </p>
                    </div>
                  </div>
                  <div className="button_div">
                    <Link to="/contact" className="parttwo_button">
                      <button className="para_two_button">
                        Get Free Quote
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="choose_partthree">
                <div className="part_twohalf">
                  <div className="icon_circle">
                    <img src={`${photourl}${w.image_path3}`} alt="prize" />
                  </div>
                  <div className="icon_heading_top">
                    <div
                      className="icon_heading"
                      dangerouslySetInnerHTML={{
                        __html: w.innovative_solution,
                      }}
                    />
                  </div>
                </div>
                <div className="part_onehalf">
                  <div className="icon_circle">
                    <img
                      src={`${photourl}${w.image_path4}`}
                      alt="prize"
                    />
                  </div>
                  <div className="icon_heading_top">
                    <div
                      className="icon_heading"
                      dangerouslySetInnerHTML={{
                        __html: w.excellent_customer_service,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Container>
    </>
  );
}

export default Banner;
