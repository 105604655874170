import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import PageNotFound from "../pages/404/404page";
import ContactUs from "../pages/contact-us/Contact-us";
import AboutUs from "../pages/about_us/About_us";
import Blog from "../pages/blog/Blog";
import BlogDetail from "../pages/blog/Blogdetail";
import FAQ from "../pages/faq/FAQ";
import Product from "../components/product/Product";
import ProductDetail from "../components/productdetail/Productdetail";
import ProductShow from "../pages/productshow/Productshow";

const Main = () => {
  return (
    <Router>
      <Header />
      <div className="wrapper">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogdetail/:name/:nameblog/:id/:name" element={<BlogDetail />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/product/:name/:name/:id/:name" element={<Product />} />
          <Route path="/productdetail" element={<ProductDetail />} />
          <Route
            path="/productshow/:name/:name/:id/:productname/:productsubcategory/:name"
            element={<ProductShow />}
          />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default Main;
