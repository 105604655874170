import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Container from "@mui/material/Container";
import http from "../../config/http";
function Footer() {
  const [data, setdata] = useState([]);
  useEffect(() => {
    http.get("product-categories").then((response) => {
      // console.log(response.data);
      setdata(response.data);
    });
  }, []);

  return (
    <>
      <div className="footermain">
        <Container maxWidth="xl">
          <div className="footerdivided">
            <div className="footerlink">
              <p className="linkParatext"> Our Products</p>
              {data.slice(0, 6).map((d, i) => (
                <Link
                  className="link_style"
                  to={{
                    pathname: `/product${d.link}`,
                  }}
                  state={d}
                  key={i}
                >
                  <p className="linkparatext2" key={i}>
                    {d.product_name}
                  </p>
                </Link>
              ))}
            </div>
            <div className="footerlink">
              <p className="linkParatext">Company</p>
              <Link to="/about-us" className="link_style">
                <p className="linkparatext2">ABOUT US</p>
              </Link>
              <Link to="/blog" className="link_style">
                <p className="linkparatext2">BLOG</p>
              </Link>
            </div>
            <div className="footerlink">
              <p className="linkParatext">Useful Links</p>
              <Link to="/" className="link_style">
                <p className="linkparatext2">HOME</p>
              </Link>
              <Link to="/faq" className="link_style">
                <p className="linkparatext2">FAQ</p>
              </Link>
              <Link to="/contact" className="link_style">
                <p className="linkparatext2">CONTACT US</p>
              </Link>
            </div>
          </div>

          <div className="footer_social_icon">
            <div className="footerlink">
              {/* <img src={Logo} alt="logo" /> */}
              <h4 className="linkParatext_two">TurnOn.co.in</h4>
              <p className="footerlowertext">
                TurnOn. One-stop solutions provider for all of your IT
                networking and consulting requirements. We offer credible and
                cutting-edge networking solutions to businesses of all sizes.
              </p>
              <p className="footerlowertext">© 2023 All Rights Reserved</p>
            </div>
            <div className="footer_social_icon_space">
              <div className="social_icon_space_footer">
                <Link
                  to="https://www.facebook.com/Turnon.co.in"
                  className="link_style"
                  target="_blank"
                >
                  <FacebookIcon />
                </Link>
              </div>
              <div className="social_icon_space_footer">
                <Link
                  to="https://twitter.com/Turnon_co_in"
                  className="link_style"
                  target="_blank"
                >
                  <TwitterIcon />
                </Link>
              </div>
              <div className="social_icon_space_footer">
                <Link
                  to="https://www.instagram.com/turnon.co.in/"
                  className="link_style"
                  target="_blank"
                >
                  <InstagramIcon />
                </Link>
              </div>
              <div className="social_icon_space_footer">
                <Link
                  className="link_style"
                  target="_blank"
                  to="https://www.linkedin.com/company/turnon-co/"
                >
                  <LinkedInIcon />
                </Link>
              </div>
            </div>
          </div>
        </Container>
        <div className="redical_footer">
          <Link
            className="link_style"
            target="_blank"
            to="https://www.radicalkonnect.com/"
          >
            <p className="power_para">Powered by Radicalkonnect</p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Footer;
